import http from '@/libs/http';
import { _news_ } from './mock/news';

export default {
  page: params => http.get('/website/news/page', { ...params }),
  //getPage: (pageable, search) => _news_.query().page(pageable),
  //getDetail: id => http.get('/website/news/detail', { id }),
  detail: id => http.get('/website/news/detail', { id }),
  getHotList: () => http.get('/website/news/hot_list'),
  getDetail: id =>
    _news_
      .query({ id })
      .list()
      .then(list => list[0])
  //getHotList: () => _news_.query({ isHot: true }).list()
};
