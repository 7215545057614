import http from '@/libs/http';
import { _notices_ } from './mock/notice';

export default {
  page: params => http.get('/website/notice/page', { ...params }),
  detail: id => http.get('/website/notice/detail', { id }),
  getPage: (pageable, search) => _notices_.query().page(pageable),
  getDetail: id =>
    _notices_
      .query({ id })
      .list()
      .then(list => list[0])
  //getPage: (pageable, search) => http.get(`/website/notice/page`, { ...pageable, search }),
  //getDetail: id => http.get(`/website/notice/detail`, { id })
};
