import LayoutBanner from './layout-banner.vue';
import LayoutFooter from './layout-footer.vue';
import LayoutHeader from './layout-header.vue';
import layoutMain from './layout-main.vue';
import layoutParty from './layout-party.vue';
import EeFileDownloadButton from '@/components/file-download-button';
export default {
  install(app) {
    app.component('LayoutBanner', LayoutBanner);
    app.component('LayoutFooter', LayoutFooter);
    app.component('LayoutHeader', LayoutHeader);
    app.component('layoutMain', layoutMain);
    app.component('layoutParty', layoutParty);
    app.component(EeFileDownloadButton.name, EeFileDownloadButton);
  }
};
